"use client";

import React, { useEffect, useState } from "react";
// import axios from "axios"; // Don't forget to import axios
import "./topBanner.css";
import Prop from "../../Pages/TopBanner/top-banner.jpg";
import bannerConfig from "../../../data/bannerConfig.json"

const TopBanner = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [active, setActive] = useState(bannerConfig.selectedOption);
  const [urlLink, setUrlLink] = useState(bannerConfig.inputValue);
  // To enable or disable the top continue banner
  // const active = false;
//console.log(active,urlLink);

    fetch('/data/bannerConfig.json')
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    // console.log(data);
    setActive(data.selectedOption);
    setUrlLink(data.inputValue);
    // Use your data here
  })
  .catch(error => {
    console.error('There was a problem with the fetch operation:', error);
  });

  // Paste the link here to redirect the page
  // const url_link = "https://www.google.com";

  const handleRedirect = () => {
    if (urlLink) {
      window.open(urlLink, "_blank");
    }
  };

  useEffect(() => {


    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth);

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  // If the banner is not active, do not render it
  if (active!=="active") return null;

  const getPadding = () => {
    if (windowWidth < 768) return "20px 50px";
    if (windowWidth < 991) return "20px 100px";
    return "20px 200px";
  };

  const buttonStyles = {
    background: "#1eb034",
    border: "1px solid #1eb034",
    color: "#ffffff",
    padding: getPadding(),
    fontFamily: "DM Sans, sans-serif",
    fontOpticalSizing: "auto",
    fontWeight: 600,
    fontSize: "30px",
    fontStyle: "normal",
    cursor: "pointer",
    borderRadius: "1px",
    transition: "transform 0.3s ease, background-color 0.3s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: isHovered ? "scale(1.1)" : "scale(1)", // Apply scale transformation on hover
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className="topbanner_main"
      onClick={handleRedirect}
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        // background: "black",
        cursor: "pointer",
        backgroundImage: `url(${Prop})`, // Use url() with the image path
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div
        className="text_css"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <p style={{ color: "white", fontSize: "30px", margin: 0, padding: 0 }}>
          Please Click Here To Continue
        </p>

        <button
          style={buttonStyles}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          CLICK TO CONTINUE
          <div
            style={{
              fontSize: "40px",
              paddingLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {">"}
          </div>
        </button>
      </div>
    </div>
  );
};

export default TopBanner;
